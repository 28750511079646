import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Button, Menu, MenuItem } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { STATUS } from 'redux/constants/index'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { Shops, OrderStatus, Profit, } from './components'
import style from './style'
import { getAnalytics, getSales, getDashboardStats } from 'redux/actions'
import Analytics from './components/Analytics'
import TopViews from './components/TopViews'
// Component styles

class Dashboard extends Component {
  state = {
    isDateSelectorOpen: false
  }
  componentDidMount = () => {
    this.props.dispatch(getAnalytics())
    this.props.dispatch(getSales())
    this.props.dispatch(getDashboardStats())
  }
  handleRangeSelector = (event) => {
    this.setState({ isDateSelectorOpen: event.currentTarget })
  }
  render () {
    const { classes, analytics, stats, sales } = this.props
    if (!analytics || analytics.status === STATUS.RUNNING) {
      return <DashboardLayout title='Dashboard'>
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </Grid>
        </div>
      </DashboardLayout>
    } else if (analytics.status === STATUS.READY) {
      return (
        <DashboardLayout title='Dashboard'>
          <div className={classes.root}>
            <Grid container justify='flex-end'>
              <Button aria-controls='simple-menu' aria-haspopup='true' onClick={this.handleRangeSelector}>
                Today <ArrowDropDownIcon />
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={this.state.isDateSelectorOpen}
                keepMounted
                open={Boolean(this.state.isDateSelectorOpen)}
                onClose={() => this.setState({ isDateSelectorOpen: false })}
              >
                <MenuItem>Last 7 days</MenuItem>
                <MenuItem>Last 30 days</MenuItem>
              </Menu>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Shops className={classes.item} shop={analytics.data.shops} />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <OrderStatus className={classes.item} orders={stats.data || {}} />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Analytics className={classes.item} details={analytics.data.customers || {}} />
              </Grid>
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <NewShops count={100} className={classes.item} />
              </Grid> */}
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Profit amount={100} className={classes.item} />
              </Grid>
              <Grid
                item
                lg={7}
                md={7}
                xl={9}
                xs={12}
              >
                {/* <SalesChart className={classes.item} details={sales.data} /> */}
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
              >
                {/* <TopViews details={stats.data.top_views || {}} /> */}
              </Grid>
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Progress className={classes.item} status={analytics.data.status} />
              </Grid> */}
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Profit className={classes.item} />
              </Grid> */}
              {/* { analytics.data.orders.completed_order || analytics.data.orders.total_open_orders ? <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <DevicesChart className={classes.item} orders={analytics.data.orders} />
              </Grid> : null} */}
            </Grid>
          </div>
        </DashboardLayout>
      )
    } else {
      return <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    stats: state.analytics.stats,
    analytics: state.analytics.dashboard,
    sales: state.analytics.sales
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Dashboard))
