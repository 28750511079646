/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest, select } from "redux-saga/effects";

import { ActionTypes, APIEndpoints } from "redux/constants/index";
import { request } from "services/client";
import { setCookie, getCookie, deleteCookie } from "services/cookie";
import CryptoJS from "crypto-js";

/**
 * Login
 */
export function* login(action) {
  try {
    const { email, password } = action.payload;
    const encrypted_password = CryptoJS.AES.encrypt(
      password,
      "U8rJ/ZhA3LdBCl6AKDNi/pAoq3urIE/PqFpAQEePcQ+T710mDC1LHGzrf0MraKXZ"
    ).toString();

    const authParams = yield select((state) => state.user.authParams);
    const response = yield call(request, APIEndpoints.LOGIN, {
      method: "POST",
      payload: { email, encrypted_password, ...authParams },
    });
    yield setCookie("auth_token_admin", response.access_token, { days: 60 });
    yield setCookie("refresh_token_admin", response.refresh_token, {
      days: 60,
    });
    yield setCookie("last_refresh_time_admin", new Date().getTime(), {
      days: 60,
    });
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err.error,
    });
  }
}

/**
 * Logout
 */
export function* logout() {
  try {
    const refreshToken = getCookie("refresh_token_admin");
    if (refreshToken) {
      yield call(request, APIEndpoints.LOGOUT, {
        method: "POST",
        payload: {
          refresh_token: refreshToken,
        },
      });
    }
    yield deleteCookie("auth_token_admin");
    yield deleteCookie("refresh_token_admin");
    yield deleteCookie("last_refresh_time_admin");
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: err,
    });
  }
}

/**
 * refresh token
 */
export function* refreshToken() {
  try {
    const lastRefreshed = getCookie("last_refresh_time_admin");
    const now = new Date().getTime();
    const timeDiff = now - lastRefreshed;
    if (timeDiff > 24 * 60 * 60 * 1000) {
      const refreshToken = getCookie("refresh_token_admin");
      if (refreshToken) {
        const response = yield call(request, APIEndpoints.REFRESH_TOKEN, {
          method: "POST",
          payload: {
            refresh_token: refreshToken,
            headers: { Authorization: `Bearer ${refreshToken}` },
          },
        });
        yield setCookie("auth_token_admin", response.access_token, {
          days: 60,
        });
        yield setCookie("refresh_token_admin", response.refresh_token, {
          days: 60,
        });
        yield setCookie("last_refresh_time_admin", new Date().getTime(), {
          days: 60,
        });
        yield put({
          type: ActionTypes.USER_LOGIN_SUCCESS,
          payload: response,
        });
      } else {
        yield put({
          type: ActionTypes.USER_LOGOUT,
          payload: {},
        });
      }
    }
  } catch (err) {
    /* istanbul ignore next */
    yield deleteCookie("auth_token_admin");
    yield deleteCookie("refresh_token_admin");
    yield deleteCookie("last_refresh_time_admin");
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
      payload: err,
    });
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN, login),
    takeLatest(ActionTypes.USER_LOGOUT, logout),
    takeLatest(ActionTypes.REFRESH_TOKEN, refreshToken),
  ]);
}
