import keyMirror from "fbjs/lib/keyMirror";

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  /**
   * Account
   */

  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,

  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  REFRESH_TOKEN: undefined,

  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,

  GET_ANALYTICS: undefined,
  GET_ANALYTICS_SUCCESS: undefined,
  GET_ANALYTICS_FAILURE: undefined,

  GET_DASHBOARD_STATS: undefined,
  GET_DASHBOARD_STATS_SUCCESS: undefined,
  GET_DASHBOARD_STATS_FAILURE: undefined,

  GET_SHOP_ANALYTICS: undefined,
  GET_SHOP_ANALYTICS_SUCCESS: undefined,
  GET_SHOP_ANALYTICS_FAILURE: undefined,

  GET_SALES: undefined,
  GET_SALES_SUCCESS: undefined,
  GET_SALES_FAILURE: undefined,

  GET_SHOPS: undefined,
  GET_SHOPS_SUCCESS: undefined,
  GET_SHOPS_FAILURE: undefined,

  GET_NEW_SHOPS: undefined,
  GET_NEW_SHOPS_SUCCESS: undefined,
  GET_NEW_SHOPS_FAILURE: undefined,

  DELETE_SHOP: undefined,
  DELETE_SHOP_SUCCESS: undefined,
  DELETE_SHOP_FAILURE: undefined,

  GET_SHOP_DETAILS: undefined,
  GET_SHOP_DETAILS_SUCCESS: undefined,
  GET_SHOP_DETAILS_FAILURE: undefined,

  GET_SHOP_PRODUCTS: undefined,
  GET_SHOP_PRODUCTS_SUCCESS: undefined,
  GET_SHOP_PRODUCTS_FAILURE: undefined,

  EXPORT_PRODUCT: undefined,
  EXPORT_PRODUCT_SUCCESS: undefined,
  EXPORT_PRODUCT_FAILURE: undefined,

  GET_SHOP_ACTIVE_ORDERS: undefined,
  GET_SHOP_ACTIVE_ORDERS_SUCCESS: undefined,
  GET_SHOP_ACTIVE_ORDERS_FAILURE: undefined,

  GET_SHOP_COMPLETED_ORDERS: undefined,
  GET_SHOP_COMPLETED_ORDERS_SUCCESS: undefined,
  GET_SHOP_COMPLETED_ORDERS_FAILURE: undefined,

  VERIFY_SHOP: undefined,
  VERIFY_SHOP_SUCCESS: undefined,
  VERIFY_SHOP_FAILURE: undefined,

  GET_SHOP_LOCATION: undefined,
  GET_SHOP_LOCATION_SUCCESS: undefined,
  GET_SHOP_LOCATION_FAILURE: undefined,

  GET_CUSTOMERS: undefined,
  GET_CUSTOMERS_SUCCESS: undefined,
  GET_CUSTOMERS_FAILURE: undefined,

  GET_SHOP_SETTINGS: undefined,
  GET_SHOP_SETTINGS_SUCCESS: undefined,
  GET_SHOP_SETTINGS_FAILURE: undefined,

  UPDATE_SHOP_SETTINGS: undefined,
  UPDATE_SHOP_SETTINGS_SUCCESS: undefined,
  UPDATE_SHOP_SETTINGS_FAILURE: undefined,

  EDIT_SHOP_LOGO: undefined,
  EDIT_SHOP_LOGO_SUCCESS: undefined,
  EDIT_SHOP_LOGO_FAILURE: undefined,

  GET_COMPLETED_ORDERS: undefined,
  GET_COMPLETED_ORDERS_SUCCESS: undefined,
  GET_COMPLETED_ORDERS_FAILURE: undefined,

  GET_ACTIVE_ORDERS: undefined,
  GET_ACTIVE_ORDERS_SUCCESS: undefined,
  GET_ACTIVE_ORDERS_FAILURE: undefined,

  GET_ORDER_DETAILS: undefined,
  GET_ORDER_DETAILS_SUCCESS: undefined,
  GET_ORDER_DETAILS_FAILURE: undefined,

  CANCEL_ORDER: undefined,
  CANCEL_ORDER_SUCCESS: undefined,
  CANCEL_ORDER_FAILURE: undefined,

  GET_MASTER_TABLE_ITEMS: undefined,
  GET_MASTER_TABLE_ITEMS_SUCCESS: undefined,
  GET_MASTER_TABLE_ITEMS_FAILURE: undefined,

  GET_PRODUCT_DETAILS: undefined,
  GET_PRODUCT_DETAILS_SUCCESS: undefined,
  GET_PRODUCT_DETAILS_FAILURE: undefined,

  GET_SHOP_SLOTS: undefined,
  GET_SHOP_SLOTS_SUCCESS: undefined,
  GET_SHOP_SLOTS_FAILURE: undefined,

  GET_SHOP_SLOT_DETAILS: undefined,
  GET_SHOP_SLOT_DETAILS_SUCCESS: undefined,
  GET_SHOP_SLOT_DETAILS_FAILURE: undefined,

  UPDATE_SHOP_SLOT: undefined,
  UPDATE_SHOP_SLOT_SUCCESS: undefined,
  UPDATE_SHOP_SLOT_FAILURE: undefined,

  ADD_SHOP_SLOT: undefined,
  ADD_SHOP_SLOT_SUCCESS: undefined,
  ADD_SHOP_SLOT_FAILURE: undefined,

  DELETE_SHOP_SLOT: undefined,
  DELETE_SHOP_SLOT_SUCCESS: undefined,
  DELETE_SHOP_SLOT_FAILURE: undefined,

  GET_SHOP_GROUPS: undefined,
  GET_SHOP_GROUPS_SUCCESS: undefined,
  GET_SHOP_GROUPS_FAILURE: undefined,
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: "idle",
  RUNNING: "running",
  READY: "ready",
  SUCCESS: "success",
  ERROR: "error",
};

const API_BASE_URL = "https://staging-api.polkart.in/admin";

/**
 * @constant {Object} ACCOUNT_API
 * @memberof Constants
 */
export const APIEndpoints = {
  /**
   * Account
   */
  LOGIN: `${API_BASE_URL}/login`,
  LOGOUT: `${API_BASE_URL}/logout`,
  SIGNUP: `${API_BASE_URL}/signup`,
  REFRESH_TOKEN: `${API_BASE_URL}/refresh_token`,

  GET_ANALYTICS: `${API_BASE_URL}/dashboard`,
  GET_SALES: `${API_BASE_URL}/order_graph`,
  GET_DASHBOARD_STATS: `${API_BASE_URL}/shops/analytics`,

  GET_CUSTOMERS: () => `${API_BASE_URL}/shops`,

  GET_SHOP_DETAILS: (id) => `${API_BASE_URL}/shop/${id}`,
  GET_SHOP_LOCATION: `${API_BASE_URL}/shop_list_map`,
  GET_NEW_SHOPS: (skip, limit, searchString) =>
    searchString
      ? `${API_BASE_URL}/not_confirmed_shops?skip=${skip}&limit=${limit}&keyword=${searchString}`
      : `${API_BASE_URL}/not_confirmed_shops?skip=${skip}&limit=${limit}`,
  GET_SHOPS: (skip, limit, searchString) =>
    searchString
      ? `${API_BASE_URL}/confirmed_shops?skip=${skip}&limit=${limit}&keyword=${searchString}`
      : `${API_BASE_URL}/confirmed_shops?skip=${skip}&limit=${limit}`,
  GET_SHOP_PRODUCTS: (id, skip, limit) =>
    `${API_BASE_URL}/shop/${id}/items?skip=${skip}&limit=${limit}`,
  EXPORT_PRODUCT: (shopId, id) => `${API_BASE_URL}/master-item-copy`,
  GET_SHOP_ACTIVE_ORDERS: (id, skip, limit) =>
    `${API_BASE_URL}/shop/${id}/open_orders?skip=${skip}&limit=${limit}`,
  GET_SHOP_COMPLETED_ORDERS: (id, skip, limit) =>
    `${API_BASE_URL}/shop/${id}/completed_orders?skip=${skip}&limit=${limit}`,
  VERIFY_SHOP: (id, status) =>
    status
      ? `${API_BASE_URL}/shop/confirm_shop/${id}`
      : `${API_BASE_URL}/shop/deactivate_shop/${id}`,
  DELETE_SHOP: (id) => `${API_BASE_URL}/shop/hide_shop/${id}`,
  GET_SHOP_SETTINGS: (id) => `${API_BASE_URL}/shop/${id}/settings`,
  UPDATE_SHOP_SETTINGS: (id) => `${API_BASE_URL}/shop/${id}/settings`,
  UPLOAD_SHOP_LOGO: (id) => `${API_BASE_URL}/business-logo/${id}`,
  EDIT_SHOP_LOGO: (id) => `${API_BASE_URL}/edit_logo_url/${id}`,
  GET_SHOP_ANALYTICS: (shopId) => `${API_BASE_URL}/shops/${shopId}/analytics `,

  GET_ACTIVE_ORDERS: (skip, limit, searchString, startingDate, endingDate) =>
    searchString
      ? `${API_BASE_URL}/open_orders?skip=${skip}&limit=${limit}&keyword=${searchString}&start_ts=${startingDate}&end_ts=${endingDate}`
      : `${API_BASE_URL}/open_orders?skip=${skip}&limit=${limit}&start_ts=${startingDate}&end_ts=${endingDate}`,
  GET_COMPLETED_ORDERS: (skip, limit, searchString, startingDate, endingDate) =>
    searchString
      ? `${API_BASE_URL}/completed_orders?skip=${skip}&limit=${limit}&keyword=${searchString}&start_ts=${startingDate}&end_ts=${endingDate}`
      : `${API_BASE_URL}/completed_orders?skip=${skip}&limit=${limit}&start_ts=${startingDate}&end_ts=${endingDate}`,
  GET_ORDER_DETAILS: (id) => `${API_BASE_URL}/order/${id}`,
  CANCEL_ORDER: (id) => `${API_BASE_URL}/cancel_order/${id}`,

  GET_MASTER_TABLE_ITEMS: (skip, limit, searchString) =>
    searchString
      ? `${API_BASE_URL}/master-items?skip=${skip}&limit=${limit}&keyword=${searchString}`
      : `${API_BASE_URL}/master-items?skip=${skip}&limit=${limit}`,
  GET_PRODUCT_DETAILS: (id, isMaster, shopId) =>
    isMaster
      ? `${API_BASE_URL}/master-item/${id}`
      : `${API_BASE_URL}/shop/${shopId}/product/${id}`,

  GET_SHOP_SLOTS: (id) => `${API_BASE_URL}/shop/${id}/slots`,
  GET_SHOP_SLOT_DETAILS: (shopId, id) =>
    `${API_BASE_URL}/shop/${shopId}/slot/${id}`,
  UPDATE_SHOP_SLOT: (shopId, id) => `${API_BASE_URL}/shop/${shopId}/slot/${id}`,
  ADD_SHOP_SLOT: (shopId) => `${API_BASE_URL}/shop/${shopId}/slot`,
  DELETE_SHOP_SLOT: (shopId, id) => `${API_BASE_URL}/shop/${shopId}/slot/${id}`,
  GET_SHOP_GROUPS: `${API_BASE_URL}/shop_groups`,
};
